<template>
  <main class="login-body">
    <RegMainInfoForm v-if="showMainInfoRegistration" />
    <ConfirmAndChangePhone v-if="showConfirmPhoneBlock" />
    <CompanyInfoForm v-if="showCompanyInfoForm" />
    <ConfirmEmail v-if="showConfirmEmailBlock" />
  </main>
</template>

<script>
import RegMainInfoForm from '@/components/registration/RegistrationMainInfo.vue';
import ConfirmAndChangePhone from '@/components/registration/ConfirmAndChangePhone.vue';
import CompanyInfoForm from '@/components/registration/CompanyInfoForm.vue';
import ConfirmEmail from '@/components/registration/ConfirmEmail.vue';
import { mapGetters } from 'vuex';
import intercom from '../utils/intercom';

export default {
  name: 'Registration',
  components: {
    RegMainInfoForm,
    ConfirmAndChangePhone,
    CompanyInfoForm,
    ConfirmEmail
  },
  computed: {
    ...mapGetters(['showConfirmPhoneBlock', 'showMainInfoRegistration', 'showCompanyInfoForm', 'showConfirmEmailBlock'])
  },
  created () {
    intercom();
  }
};
</script>
