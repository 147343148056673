<template>
  <div class="px-8">
    <div>
      <h3 class="typography-1 color-black">Follow the link</h3>
      <div class="typography-11 color-2 mb-24">You receive from {{emailToConfirm}}</div>
      <div class="d-flex justify-between align-center mt-24">
        <span class="typography-6 color-2 mb-16" v-if="timeoutToSendCode">{{timeoutToSendCode}}</span>
        <span class="typography-6 color-2" v-if="successfulSendCode">{{successfulSendCode}}</span>
        <span class="typography-6 color-2" v-else>Did not get the link?</span>
        <a id="send-email-link" href="#" class="results-btn fw-600" @click.prevent="sendCodeAgain" v-if="!timer">
          Send again
        </a>
        <span class="results-btn fw-600" v-else>{{timer}} seconds</span>
      </div>
      <button id="back-to-company-info" class="opacity-btn mr-16 mt-16" @click="backToCompanyInfo">
        <Icon name="arrow-left-blue" class="mr-8" />
        Wrong email?
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '../app/Icon.vue';
import amplitude from 'amplitude-js';

export default {
  name: 'ConfirmEmail',
  data () {
    return {
      timer: null
    };
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['emailToConfirm', 'timeoutToSendCode', 'successfulSendCode'])
  },
  methods: {
    sendCodeAgain () {
      this.checkTimer();

      const event = 'resend_link';
      amplitude.getInstance().logEvent(event);

      this.$store.dispatch('sendCodeAgain', this.emailToConfirm);
    },
    checkTimer () {
      this.timer = 40;
      const timerId = setInterval(() => {
        --this.timer;
      }, 1000);
      setTimeout(() => { clearInterval(timerId); this.timer = null; }, 40000);
    },
    backToCompanyInfo () {
      this.$store.commit('setShowCompanyInfoForm', true);
      this.$store.commit('setShowConfirmEmailBlock', false);
    }
  }
};
</script>
