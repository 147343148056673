<template>
  <div class="px-8">
    <div v-if="showPhoneCodeForm">
      <h3 class="typography-1 color-black">Enter the code</h3>
      <div class="typography-11 color-2 mb-24">You receive via SMS to {{phoneToConfirm || accountState.phone}}</div>
      <div class="input-block mb-12">
        <input
          type="text"
          placeholder="Enter the code"
          class="input bg-form"
          :class="errors.phoneCode && v$.phoneCode.$error ? 'error-border' : ''"
          v-model.trim="v$.phoneCode.$model"
          maxlength="6"
          id="code"
        >
        <span class="input-block__counter">{{phoneCode.length}} / {{v$.phoneCode.maxLength.$params.max}}</span>
      </div>
      <div class="error" v-if="errors.phoneCode && v$.phoneCode.$error">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.phoneCode.minLength.$invalid">Code must have at least {{v$.phoneCode.minLength.$params.min}} letters.</span>
          <span v-show="v$.phoneCode.maxLength.$invalid">Code must have no more than {{v$.phoneCode.maxLength.$params.max}} letters.</span>
          <span v-show="v$.phoneCode.numeric.$invalid">Code must be numeric</span>
        </div>
      </div>
      <div class="error" v-if="phoneError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span>{{phoneError}}</span>
        </div>
      </div>
      <div class="d-flex flex-column mt-24">
        <span class="typography-6 color-2 mb-16" v-if="timeoutToSendCode || phoneTimeout">{{timeoutToSendCode || phoneTimeout}}</span>
        <div class="d-flex justify-between align-center">
          <span class="typography-6 color-2" v-if="successfulSendCode">{{successfulSendCode}}</span>
          <span class="typography-6 color-2" v-else>Did not get the sms?</span>
          <a
            id="send-code-again"
            href="#"
            class="results-btn fw-600 h-auto p-0"
            @click.prevent="sendCodeAgain"
            v-if="$route.name === 'Registration'"
          >
            Send again
          </a>
          <a
            id="send-code-again-sl-area"
            href="#"
            class="results-btn fw-600 h-auto p-0"
            @click.prevent="sendCodeAgainSlArea"
            v-if="$route.name === 'Login'"
          >
            Send again
          </a>
        </div>
      </div>
      <div class="container px-0 mt-32">
        <div class="row mx--8 confirm-buttons">
          <div class="col-12 col-md-6 px-8">
            <button id="go-back" class="opacity-btn w-sm-100" @click="goBack" v-if="$route.name === 'Registration'">
              Wrong number?
            </button>
            <button id="change-phone" class="opacity-btn w-sm-100" @click="openChangePhone" v-else>Wrong number?</button>
          </div>
          <div class="col-12 col-md-6 px-8 mb-16">
            <button
              id="confirm-number"
              class="primary-btn typography-base w-sm-100"
              @click="sendConfirmPhone"
              data-recording-click-props='{"reg_stage":"phone"}'
              v-if="$route.name === 'Registration'"
              :disabled="!phoneCode || loading"
            >
              Confirm number
            </button>
            <button
              id="confirm-number-sl-area"
              class="primary-btn typography-base w-sm-100"
              @click="confirmPhoneSlArea"
              data-recording-click-props='{"reg_stage":"phone"}'
              v-if="$route.name === 'Login'"
              :disabled="!phoneCode || loading"
            >
              Confirm number
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showChangePhoneForm">
      <h3 class="typography-1 color-black">Please enter the correct number</h3>
      <div class="typography-11 color-2 mb-24">You will receive a code via SMS</div>
      <div class="mb-12">
          <InputPhone @writePhone="writePhone" @itiPhoneState="itiPhoneState" @checkPhoneError="checkPhoneError"/>
      </div>
      <div class="error" v-if="phoneError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span>{{phoneError}}</span>
        </div>
      </div>
      <div class="d-flex mt-32 mb-32">
        <button id="back-to-reg" class="opacity-btn mr-16" @click="backToReg" v-if="$route.name === 'Registration'">
          <Icon name="arrow-left-blue" class="mr-8"/>
          Back
        </button>
        <button id="back-to-login" class="opacity-btn mr-16" @click="backToLogin" v-if="$route.name === 'Login'">
          <Icon name="arrow-left-blue" class="mr-8"/>
          Back
        </button>
        <button
          id="send-code"
          class="primary-btn typography-base"
          :disabled="!phone || loading"
          @click="changePhoneAction"
        >
          Send me code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../app/Icon.vue';
import InputPhone from '../app/InputPhone.vue';
import { minLength, maxLength, numeric } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { eventListener } from '../../utils/helpers';
import axios from 'axios';
import amplitude from 'amplitude-js';

export default {
  name: 'ConfirmPhoneForm',
  components: {
    Icon,
    InputPhone
  },
  data () {
    return {
      changePhone: false,
      phone: '',
      phoneCode: '',
      loading: false,

      // intlTelInput library state
      itiPhone: null,
      v$: useVuelidate(),
      errors: {
        phoneCode: false,
        phone: false
      }
    };
  },
  validations () {
    return {
      phoneCode: {
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric
      }
    };
  },
  emits: ['backToLogin'],
  computed: {
    ...mapGetters(['phoneTimeout', 'phoneError', 'accountState', 'showPhoneCodeForm', 'showChangePhoneForm', 'signUpToken', 'phoneToConfirm', 'timeoutToSendCode', 'successfulSendCode'])
  },
  methods: {
    checkErrorsPhoneCode () {
      this.errors.phoneCode = false;
      this.errors.phoneCode = this.v$.phoneCode.$error;
      if (this.errors.phoneCode) {
        return 'error';
      }
    },
    async sendConfirmPhone () {
      const checkErrors = this.checkErrorsPhoneCode();
      if (checkErrors === 'error') return;

      const event = 'reg_confirm';
      const eventProperties = {
        type: 'number'
      };
      amplitude.getInstance().logEvent(event, eventProperties);

      try {
        this.loading = true;
        this.$store.commit('setErrorPhone', '');
        const body = {
          code: this.phoneCode,
          transport: 2,
          signup_token: this.signUpToken
        };
        await axios.post('/api/v3/signup/verification', body);
        this.$store.commit('setShowPhoneCodeForm', false);
        this.$store.commit('setShowChangePhoneForm', false);
        this.$store.commit('setShowConfirmPhoneBlock', false);
        this.$store.commit('setShowCompanyInfoForm', true);
        this.$store.commit('setMainInfoFromReg', null);
        this.loading = false;
      } catch (error) {
        this.errors.phoneCode = true;
        this.loading = false;
        if (error.response?.data?.fields?.code) {
          this.$store.commit('setErrorPhone', error.response.data.fields.code[0].detail);
        } else if (error.response?.data?.detail) {
          this.$store.commit('setErrorPhone', error.response.data.detail);
        } else {
          this.$store.commit('setErrorPhone', 'Something went wrong, try again later');
        }
      }
    },
    sendCodeAgain () {
      const event = 'resend_sms';
      amplitude.getInstance().logEvent(event);

      this.$store.dispatch('sendCodeAgain', this.phoneToConfirm);
    },
    async sendCodeAgainSlArea () {
      try {
        this.$store.commit('setSuccessfulSendCode', null);
        await this.$store.dispatch('sendConfirmPhone');
        this.$store.commit('setSuccessfulSendCode', 'The code has been successfully sent');
      } catch (e) {
        console.error(e);
      }
    },
    openChangePhone () {
      this.$store.commit('setErrorPhone', '');
      this.$store.commit('setShowPhoneCodeForm', false);
      this.$store.commit('setShowChangePhoneForm', true);
    },
    // Write phone and itiPhone state from input phone component
    writePhone (val) {
      this.phone = val;
    },
    itiPhoneState (val) {
      this.itiPhone = val;
    },
    backToReg () {
      this.$store.commit('setShowPhoneCodeForm', false);
      this.$store.commit('setShowChangePhoneForm', false);
      this.$store.commit('setShowConfirmPhoneBlock', false);
      this.$store.commit('setShowMainInfoRegistration', true);
    },
    async changePhoneAction () {
      eventListener.emit('checkPhoneError');
      if (this.errors.phone) return;

      try {
        this.loading = true;
        this.$store.commit('setErrorPhone', '');
        const payload = {
          method: 'PATCH',
          url: '/api/v2/user',
          body: {
            phone: this.itiPhone.getNumber()
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('setShowPhoneCodeForm', true);
        this.$store.commit('setShowChangePhoneForm', false);
        this.$store.dispatch('sendConfirmPhone');
        this.$store.commit('setPhone', this.itiPhone.getNumber());
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const res = error.response;
        if (res.data.fields.phone && res.data.fields.phone.length) {
          this.$store.commit('setErrorPhone', res.data.fields.phone[0].detail);
        } else {
          this.$store.commit('setErrorPhone', 'Something went wrong, try again later');
        }
      }
    },
    checkPhoneError (val) {
      this.errors.phone = val;
    },
    goBack () {
      this.$store.commit('setShowConfirmPhoneBlock', false);
      this.$store.commit('setShowMainInfoRegistration', true);
    },
    async confirmPhoneSlArea () {
      const checkErrors = this.checkErrorsPhoneCode();
      if (checkErrors === 'error') return;

      this.loading = true;
      try {
        await this.$store.dispatch('sendConfirmPhone', { code: this.phoneCode });
        if (!this.phoneError) {
          await this.$store.dispatch('createNewCase');
          window.roistat.event.send('gamayunreg');
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    backToLogin () {
      this.$emit('backToLogin');
    }
  }
};
</script>
