export function nameValidator (value) {
  if (value.length === 0) {
    return true;
  }
  return !!value.match(/^[а-яА-ЯёЁa-zA-Z'`]+( [а-яА-ЯёЁa-zA-Z'`]+)*$/);
}

export function nameInAllLanguages (value) {
  if (value.includes('--') || value.startsWith('-')) {
    return false;
  }
  const val = value.replaceAll(/[-']+/gi, '');
  const reg = /^[\p{L}\p{M} ]*$/u;
  return reg.test(val);
}

export function checkPhoneInputError (value) {
  if (value) {
    return /^\+?\d*$/.test(value);
  } else return true;
}

export function aliasValidator (value) {
  if (!value) return true;
  return /^[\w\-.\d]*$/.test(value);
}

export function profileUrlValidator (url) {
  if (!url) return true;
  if (/^https?:\/\/(www\.)?instagram.com\/[a-zA-Z0-9._]{1,30}\/?$/.test(url)) {
    return true;
  }
  if (/^https?:\/\/(www\.)?twitter.com\/[a-zA-Z0-9_]{4,15}\/?$/.test(url)) {
    return true;
  }
  if (/^https?:\/\/(www\.)?facebook.com\/profile\.php\?id=[0-9]+$/.test(url)) {
    return true;
  }
  if (/^https?:\/\/(www\.)?facebook.com\/[a-zA-Z0-9.]{5,50}\/?$/.test(url)) {
    return true;
  }
  return /^https?:\/\/(www\.)?linkedin.com\/in\/[a-zA-Z0-9-_]+\/?$/.test(url);
}

export function profileIDValidator (value) {
  if (!value) return true;
  return /^[a-zA-Z0-9-_]+$/.test(value);
}
