<template>
  <div class="px-8">
    <h1 class="typography-1 color-black" data-type="sign-up-header">Get started</h1>
    <div class="typography-11 color-2 mb-32">
      Already have an account?
      <router-link to="/login" class="color-link" id="sign-in">Sign in</router-link>
    </div>
    <div class="typography-caption color-2 mb-8 let-space-08">Personal info</div>
    <div class="container px-0">
      <div class="row mb-12 mx--8">
        <div class="col-12 col-lg-6 px-8">
          <input
            id="reg-name"
            type="text"
            placeholder="Name"
            class="input bg-form mb-md-8"
            maxlength="20"
            :class="errors.firstname && v$.firstname.$error ? 'error-border' : ''"
            v-model.trim="v$.firstname.$model"
          >
        </div>
        <div class="col-12 col-lg-6 px-8">
          <input
            id="reg-surname"
            type="text"
            placeholder="Surname"
            class="input bg-form"
            maxlength="20"
            :class="errors.lastname && v$.lastname.$error ? 'error-border' : ''"
            v-model.trim="v$.lastname.$model"
          >
        </div>
      </div>
    </div>
    <div class="error" v-if="errors.firstname && v$.firstname.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.firstname.maxLength.$invalid">
          Firstname must have no more than {{v$.firstname.maxLength.$params.max}} letters.
        </span>
        <span v-show="v$.firstname.nameInAllLanguages.$invalid">Firstname must contain only letters</span>
      </div>
    </div>
    <div class="error" v-if="errors.lastname && v$.lastname.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.lastname.maxLength.$invalid">
          Surname must have no more than {{v$.lastname.maxLength.$params.max}} letters.
        </span>
        <span v-show="v$.lastname.nameInAllLanguages.$invalid">Surname must contain only letters</span>
      </div>
    </div>
    <div class="mb-12 mt-24">
      <div class="d-flex justify-between mb-8">
        <label class="typography-caption color-2 mr-16 let-space-08">Number</label>
        <label class="typography-caption color-theme let-space-08 right-label">An sms will be sent to this number for account authorization</label>
      </div>
      <InputPhone @writePhone="writePhone" @itiPhoneState="itiPhoneState" @checkPhoneError="checkPhoneError"/>
    </div>
    <div class="error" v-if="serverErrors.length">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-for="error in serverErrors" :key="error">{{error}}</span>
      </div>
    </div>
    <div class="registration-footer mt-32">
      <button
        id="reg-next-step"
        class="primary-btn typography-base w-sm-100"
        data-recording-click-props='{"reg_stage":"start"}'
        :disabled="emptyFields || loading" @click="goToConfirm"
      >
        Next step
      </button>
    </div>
  </div>
</template>

<script>
import { maxLength } from '@vuelidate/validators';
import Icon from '../app/Icon.vue';
import InputPhone from '../app/InputPhone.vue';
import { nameInAllLanguages } from '@/utils/validators.js';
import { useVuelidate } from '@vuelidate/core';
import { eventListener } from '../../utils/helpers';
import { mapGetters } from 'vuex';
import axios from 'axios';
import amplitude from 'amplitude-js';

export default {
  name: 'RegisterForm',
  components: {
    Icon,
    InputPhone
  },
  data () {
    return {
      firstname: '',
      lastname: '',
      phone: '',
      serverErrors: [],
      loading: false,

      // intlTelInput library state
      itiPhone: null,
      v$: useVuelidate(),
      errors: {
        firstname: false,
        lastname: false,
        phone: false
      }
    };
  },
  validations () {
    return {
      firstname: {
        maxLength: maxLength(20),
        nameInAllLanguages
      },
      lastname: {
        maxLength: maxLength(20),
        nameInAllLanguages
      }
    };
  },
  created () {
    if (this.mainInfoFromReg) {
      this.firstname = this.mainInfoFromReg.firstname;
      this.lastname = this.mainInfoFromReg.lastname;
      this.phone = this.mainInfoFromReg.phone;
    }
  },
  mounted () {
    const event = 'reg_view';
    amplitude.getInstance().logEvent(event);
  },
  computed: {
    ...mapGetters(['mainInfoFromReg']),
    emptyFields () {
      return !this.firstname || !this.lastname || !this.phone;
    }
  },
  methods: {
    // Write phone and itiPhone state from input phone component
    writePhone (val) {
      this.phone = val;
    },
    itiPhoneState (val) {
      this.itiPhone = val;
    },
    async goToConfirm () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      const event = 'reg_first';
      amplitude.getInstance().logEvent(event);

      try {
        this.loading = true;
        this.serverErrors = [];

        const body = {
          phone: this.itiPhone.getNumber(),
          name: this.firstname + ' ' + this.lastname
        };
        const signUpToken = await axios.post('/api/v3/signup/start', body);
        this.$store.commit('setSignUpToken', signUpToken.data.signup_token);

        const body2 = {
          value: this.itiPhone.getNumber(),
          signup_token: signUpToken.data.signup_token
        };
        await axios.post('/api/v3/signup/verification/request', body2);

        this.$store.commit('setPhone', this.itiPhone.getNumber());
        this.$store.commit('setShowMainInfoRegistration', false);
        this.$store.commit('setShowConfirmPhoneBlock', true);
        this.$store.commit('setShowPhoneCodeForm', true);

        this.$store.commit('setMainInfoFromReg', {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response?.data?.detail) {
          this.serverErrors.push(error.response.data.detail);
        } else {
          this.serverErrors.push('Something went wrong, try again later');
        }
      }
    },
    checkErrorsForShow () {
      this.errors.firstname = false;
      this.errors.lastname = false;

      this.errors.firstname = this.v$.firstname.$error;
      this.errors.lastname = this.v$.lastname.$error;
      eventListener.emit('checkPhoneError');
      if (this.errors.firstname || this.errors.lastname || this.errors.phone) {
        return 'error';
      }
    },
    checkPhoneError (val) {
      this.errors.phone = val;
    }
  }
};
</script>
