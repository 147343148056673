<template>
  <div class="px-8">
    <h1 class="typography-1 color-black">Company info</h1>
    <div class="typography-11 color-2 mb-24">In accordance with our KYC policy, we need to know who is using our product</div>
    <div class="container mb-24 px-0">
      <div class="row mx--8">
        <div class="col-12 col-lg-6 px-8 flex-column mb-md-8">
          <label for="company" class="typography-caption color-2 mb-8 let-space-08">Company Name</label>
          <input type="text" placeholder="Enterprise" id="company" class="input bg-form" maxlength="50" :class="errors.company && v$.company.$error ? 'error-border' : ''" v-model.trim="v$.company.$model">
        </div>
        <div class="col-12 col-lg-6 px-8 flex-column">
          <label for="role" class="typography-caption color-2 mb-8 let-space-08">Role in company</label>
          <input type="text" placeholder="Employee" id="role" maxlength="50" class="input bg-form" :class="errors.role && v$.role.$error ? 'error-border' : ''" v-model.trim="v$.role.$model">
        </div>
      </div>
    </div>
    <div class="error" v-if="errors.company && v$.company.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.company.maxLength.$invalid">Company must have no more than {{v$.company.maxLength.$params.max}} letters.</span>
      </div>
    </div>
    <div class="error" v-if="errors.role && v$.role.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.role.maxLength.$invalid">Role must have no more than {{v$.role.maxLength.$params.max}} letters.</span>
      </div>
    </div>
    <div class="mb-12 d-flex flex-column">
      <label for="email" class="typography-caption color-2 mb-8 let-space-08">Your business email</label>
      <input type="email" id="email" placeholder="cupper@rt.com" class="input bg-form pr-16" :class="errors.email && v$.email.$error ? 'error-border' : ''" v-model.trim="v$.email.$model">
    </div>
    <div class="error" v-if="errors.email && v$.email.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.email.maxLength.$invalid">Email must have no more than {{v$.email.maxLength.$params.max}} letters.</span>
        <span v-show="v$.email.email.$invalid">Wrong email address</span>
      </div>
    </div>
    <div class="error" v-if="errors.serverError">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="errors.serverError">{{errors.serverError}}</span>
      </div>
    </div>
    <div class="registration-footer mt-32">
      <button
        id="company-info-form-submit"
        class="primary-btn typography-base m-sm-0 mr-32"
        :disabled="emptyFields || loading"
        @click="goToConfirm"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import { maxLength, email } from '@vuelidate/validators';
import Icon from '../app/Icon.vue';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import axios from 'axios';
import amplitude from 'amplitude-js';

export default {
  name: 'CompanyInfoForm',
  components: {
    Icon
  },
  data () {
    return {
      company: '',
      role: '',
      email: '',
      loading: false,

      v$: useVuelidate(),
      errors: {
        company: false,
        role: false,
        email: false,
        serverError: ''
      }
    };
  },
  validations () {
    return {
      company: {
        maxLength: maxLength(50)
      },
      role: {
        maxLength: maxLength(50)
      },
      email: {
        email,
        maxLength: maxLength(320)
      }
    };
  },
  created () {
    if (this.companyInfoFromReg) {
      this.company = this.companyInfoFromReg.company;
      this.role = this.companyInfoFromReg.role;
      this.email = this.companyInfoFromReg.email;
    }
  },
  computed: {
    ...mapGetters(['signUpToken', 'companyInfoFromReg']),
    emptyFields () {
      return !this.company || !this.role || !this.email;
    }
  },
  methods: {
    async goToConfirm () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      const event = 'reg_company';
      const eventProperties = {
        company: !!this.company,
        role: !!this.role,
        business_email: !!this.email
      };
      amplitude.getInstance().logEvent(event, eventProperties);

      try {
        this.loading = true;
        this.errors.serverError = '';
        const body = {
          company: this.company,
          role: this.role,
          email: this.email
        };
        await axios.patch(`/api/v3/signup/status/${this.signUpToken}`, body);

        const body2 = {
          value: this.email,
          signup_token: this.signUpToken
        };
        await axios.post('/api/v3/signup/verification/request', body2);
        this.$store.commit('setEmail', this.email);
        this.$store.commit('setShowCompanyInfoForm', false);
        this.$store.commit('setShowConfirmEmailBlock', true);
        this.$store.commit('setCompanyInfoFromReg', {
          company: this.company,
          role: this.role,
          email: this.email
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response?.data?.detail) {
          this.errors.serverError = error.response?.data?.detail;
        } else {
          this.errors.serverError = 'Something went wrong, try again later';
        }
      }
    },
    checkErrorsForShow () {
      this.errors.company = false;
      this.errors.role = false;
      this.errors.email = false;

      this.errors.company = this.v$.company.$error;
      this.errors.role = this.v$.role.$error;
      this.errors.email = this.v$.email.$error;
      if (this.errors.company || this.errors.role || this.errors.email) {
        return 'error';
      }
    }
  }
};
</script>
